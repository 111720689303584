import React from 'react'

import membership from './images/membership.svg'
import check from './images/check.svg'
import PrimaryButton from '../../../shared/Buttons/PrimaryButton/PrimaryButton'

import styles from './BuyMembershipCard.module.css'

class BuyMembershipCard extends React.Component {
    render() {
        const title = this.props.data.item.title; 
        const description = this.props.data.item.descriptionText;
        const features = this.props.data.features;
        const price = this.props.data.item.price;

        return (
            <div className={styles['content']} ref={this.props.innerRef}>
                <div className={styles['circle']}>
                    <img src={membership} alt='' className={styles['image']} />
                </div>
                <div className={styles['info']}>
                    <div className={styles['info-container']}>
                        <h3 className={styles['title']}>{title}</h3>
                        <p className={styles['description']}>{description}</p>
                        <div className={styles['features']}>
                            {
                                features.map((feature, featureIndex) => {
                                    return  <div className={styles['feature']} key={featureIndex}>
                                                <img src={check} alt='' className={styles['feature-check']} />
                                                <span className={styles['feature-text']}>{feature.value}</span>
                                            </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={styles['buy-container']}>
                        <div className={styles['price']}>
                            <span className={styles['dollars']}>${String(price).split('.')[0]}</span>
                            <span className={styles['cents']}>{String(price).split('.')[1]}</span>
                        </div>
                        <PrimaryButton className={styles['button']} noAnimate={true} onClick={this.props.onClick}>Buy Now</PrimaryButton>
                    </div>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <BuyMembershipCard 
  innerRef={ref} {...props}
/>);