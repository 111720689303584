import React from 'react'

import styles from './Plan.module.css'

class Plan extends React.Component {
    // Component
    render() {
        const { title, description, price } = this.props.data;

        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef}>
                <div className={styles['circle']}>
                    <img src={this.props.image} alt='' className={styles['image']} />
                </div>
                <h3 className={styles['title']}>{title}</h3>
                <p className={styles['description']}>{description}</p>
                <div className={styles['price']}>
                {
                    this.props.from &&
                    <span className={styles['from']}>from</span>
                }
                    <span className={styles['dollars']}>${String(price).split('.')[0]}</span>
                    <span className={styles['cents']}>{String(price).split('.')[1]}</span>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <Plan 
  innerRef={ref} {...props}
/>);