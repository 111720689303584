import React from 'react'

import ArrowButton from '../../../shared/Buttons/ArrowButton/ArrowButton'

import styles from './QuickBuy.module.css'

class QuickBuy extends React.Component {
    render() {
        return (
            <div className={styles['content']} onClick={this.props.onClick} ref={this.props.innerRef} onMouseEnter={(e) => this.props.onMouseEnter(this.props.index)} onMouseLeave={(e) => this.props.onMouseLeave(this.props.index)}>
                <div className={styles['info']}>
                    <span className={styles['title']}>{this.props.title}</span>
                    <div className={styles['price']}>
                        <span className={styles['dollars']}>${String(this.props.price).split('.')[0]}</span>
                        <span className={styles['cents']}>{String(this.props.price).split('.')[1]}</span>
                    </div>
                </div>
                <ArrowButton className={styles['arrow']} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <QuickBuy 
  innerRef={ref} {...props}
/>);