import React from 'react'

import error from './images/error.svg'

import styles from './TextArea.module.css'

class TextArea extends React.Component {
    render() {
        return (
            <div className={`${styles['content']} ${this.props.className} a4-input`}>
                <textarea className={styles['text']} type={this.props.type} name={this.props.name} placeholder={this.props.placeholder} required={this.props.required} />
                <span className={`${styles['error']} a4-input-error`}>This is an error</span>
                <img src={error} alt='' className={styles['image']} />
            </div>
        )
    }
}

export default TextArea;