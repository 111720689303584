import React from 'react'

import styles from './HowItWorksCard.module.css'

class HowItWorksCard extends React.Component {
    // Component
    render() {
        const { header } = this.props.data;

        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef} onMouseEnter={(e) => this.props.onMouseEnter(this.props.index)} onMouseLeave={(e) => this.props.onMouseLeave(this.props.index)}>
                <div className={styles['number']}>
                    <span>{`0${this.props.number}`}</span>
                </div>
                <p className={styles['title']}>{header}</p>
                <img src={this.props.image} alt='' className={styles['image']} ref={ref => this.image = ref} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <HowItWorksCard 
  innerRef={ref} {...props}
/>);